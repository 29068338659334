import { useWeb3React } from '@web3-react/core';
import { Form, Tooltip } from 'antd';
import carvIcon from 'assets/images/carv_id.png';
import metaMaskIcon from 'assets/logo-metamask.png';
import geminiIcon from 'assets/vectors/login-gemini.svg';
import { GaActions, GaCategories, GaLabels } from 'constants/enum/GaEvent';
import { PitRouter } from 'constants/routers';
import { ethers } from 'ethers';
import useEnableNotification from 'hooks/notification/useEnableNotification';
import { useCheckConnectedWallet } from 'hooks/player/useCheckConnectedWallet';
import useConnectors from 'hooks/useConnectors';
import useOneSignal from 'hooks/useOneSignal';
import { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import signMessage from 'services/Contracts/signMessage';
import { postRegister, verifyRecaptcha } from 'services/api';
import { everflowConversion } from 'utils/everflow';

import {
  CustomFormItem,
  FormWrapper,
  InputStyle,
  PasswordInputStyle,
  RegisterConnectButton,
  RegisterConnectButtonsWrapper,
  RegisterConnectText,
  RegisterConnectedInfo,
  RegisterFirstStepFooter,
  RegisterStepButton,
  RegisterStepButtonWrapper,
  RegisterStepDescription,
  RegisterStepLink,
  RegisterStepTitle,
  TextError,
  Wrapper,
} from './styled';

interface IProps {
  onBack: () => void;
  onContinue: () => void;
  setWaitToVerifyEmailAddress: (value: string) => void;
}

export const RegisterForm = ({
  onBack,
  onContinue,
  setWaitToVerifyEmailAddress,
}: IProps) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const navigate = useNavigate();

  const [inputPass, setInputPass] = useState<string>();
  const [emailAddress, setEmailAddress] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);

  const [acceptNotification, setAcceptNotification] = useState<boolean>(false);
  const { initOneSignal } = useOneSignal();
  const isEnableNotification = useEnableNotification();

  const [typeOfConnector, setTypeOfConnector] = useState<string>();
  const { account } = useWeb3React();

  const { onConnectMetamaskWallet, disconnect } = useConnectors();

  const [checkWalletParams, setCheckWalletParams] = useState<{
    walletAddress?: string;
  }>({});
  const { isConnected, loading: checkWalletLoading } =
    useCheckConnectedWallet(checkWalletParams);

  useEffect(() => {
    setCheckWalletParams({
      walletAddress: account,
    });
  }, [account]);

  useEffect(() => {
    (async () => {
      await everflowConversion();
    })();
  }, []);

  let code;

  if (window.location.pathname.split('/').pop() !== 'register') {
    code = window.location.pathname.split('/').pop();
  }

  const handleSubmit = async () => {
    ReactGA.event({
      category: GaCategories.SIGNUP,
      action: GaActions.ACTION_SIGNUP_FORM,
      label: GaLabels.LABEL_SIGNUP_FORM,
    });

    const captchaValue = recaptchaRef.current.getValue();
    if (!captchaValue) {
      toast.error('Please verify the reCAPTCHA!');
    } else {
      setLoading(true);
      const resVerifyCaptcha = await verifyRecaptcha(captchaValue);
      if (
        resVerifyCaptcha?.success &&
        resVerifyCaptcha?.payload?.data?.success
      ) {
        if (account && !checkWalletLoading && isConnected) {
          return;
        }
        // setLoading(true);
        let signMess;
        if (account && typeOfConnector) {
          if (typeOfConnector === 'Metamask') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const dataSignature = await signMessage(account, provider.provider);
            signMess = dataSignature;
          } else {
            setLoading(false);
            return;
          }
        }
        if (account && !signMess?.success) {
          toast.error('User denied message signature.');
          setLoading(false);
          return;
        }
        const register = await postRegister({
          walletAddress: account,
          emailAddress,
          password: inputPass,
          signature: account ? signMess.data.signature : undefined,
          message: account ? signMess.data.message : undefined,
          affiliateCode: code || undefined,
          notificationType: [],
        });
        if (register?.success) {
          // LocalhostStorage.set('accessToken', register?.payload?.data?.accessToken);
          setWaitToVerifyEmailAddress(emailAddress);
          onContinue();
        } else toast.error(register?.message || 'Registration failed');
        setLoading(false);
      } else {
        toast.error(
          resVerifyCaptcha?.message || 'Please verify the reCAPTCHA!',
        );
        setLoading(false);
      }
    }
  };

  const carvSingUp = () => {
    const currentTimestamp = Date.now();
    const carvAuthUrl = `https://auth.carv.io/auth/authorize?client_id=${process.env.REACT_APP_CARV_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_CARV_REDIRECT_URI}&response_type=code&scope=${process.env.REACT_APP_CARV_SCOPE}&state=${currentTimestamp}`;
    window.location.href = carvAuthUrl;
  };

  // const [termsAccepted, setTermsAccepted] = useState(false);

  // const handleTermsChange = (e: any) => {
  //   setTermsAccepted(e.target.checked);
  // };

  return (
    <Wrapper>
      {/* <RegisterBackButton onClick={onBack}>
        <img src={backImg} alt="back" />
        <span>Back</span>
      </RegisterBackButton> */}
      <RegisterStepTitle>Please enter details</RegisterStepTitle>

      <FormWrapper>
        <Form
          className="register-form"
          onFinish={handleSubmit}
          //   initialValues={{ remember: true }}
          autoComplete="off"
          disabled={loading || checkWalletLoading}
        >
          <CustomFormItem
            name="email"
            rules={[
              {
                pattern: new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$'),
                message: 'Invalid email',
              },
            ]}
          >
            <InputStyle
              onChange={e => setEmailAddress(e.target.value)}
              required
              placeholder="Email address"
              onKeyDown={e => e.keyCode === 32 && e.preventDefault()}
            />
          </CustomFormItem>
          <Form.Item
            name="password"
            rules={[
              {
                min: 8,
                message: 'Password must be minimum 8 characters.',
              },
              {
                max: 30,
                message: 'Password must be maximum 30 characters.',
              },
              {
                validator: (_, value, callback) => {
                  if (value && !new RegExp('[0-9]').test(value)) {
                    callback('Password must contains at least one number.');
                  } else {
                    callback();
                  }
                },
              },
              {
                validator: (_, value, callback) => {
                  if (value && !new RegExp('[a-zA-Z]').test(value)) {
                    callback('Password must contains at least one alphabet.');
                  } else {
                    callback();
                  }
                },
              },
              {
                validator: (_, value, callback) => {
                  if (value && !new RegExp('[!@#$%^&*()+=_-]').test(value)) {
                    callback(
                      'Password must contain at least one special character \n from the following: !@#$%^&*()+=_-',
                    );
                  } else {
                    callback();
                  }
                },
              },
              {
                validator: (_, value, callback) => {
                  if (value && !/^[^<>?,."|~`';:{}[\]/\\]+$/.test(value)) {
                    callback(
                      'Password must not contain special characters \n from the following : <,>.?/|\\~`:;"\'{[]} ',
                    );
                  } else {
                    callback();
                  }
                },
              },
              {
                validator: (_, value, callback) => {
                  if (value && new RegExp('\\s+').test(value)) {
                    callback('Password must not contain any white space.');
                  } else {
                    callback();
                  }
                },
              },
            ]}
            validateFirst
          >
            <PasswordInputStyle
              onChange={e => setInputPass(e.target.value)}
              type="password"
              placeholder="Password"
              required
            />
          </Form.Item>
          <Form.Item
            className="mb-2"
            name="re-pass"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Password doesn’t match'));
                },
              }),
            ]}
            dependencies={['password']}
          >
            <PasswordInputStyle
              type="password"
              placeholder="Confirm password"
              required
            />
          </Form.Item>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          />
          {/* {isEnableNotification && (
            <CheckboxWrapper
              onChange={e => handleAcceptNotification(e.target.checked)}
            >
              I accept to receive notification browser and email
            </CheckboxWrapper>
          )} */}
          <RegisterFirstStepFooter>
            <RegisterStepDescription>
              Already have an account?
            </RegisterStepDescription>
            <RegisterStepLink
              disabled={loading}
              onClick={() => {
                navigate(PitRouter.LOGIN);
              }}
            >
              Log In
            </RegisterStepLink>
          </RegisterFirstStepFooter>

          <RegisterStepButtonWrapper>
            <RegisterStepButton htmlType="submit" loading={loading}>
              Continue
            </RegisterStepButton>
          </RegisterStepButtonWrapper>
        </Form>
      </FormWrapper>

      <RegisterConnectText>
        <div className="inline-line"></div>
        <div>Connect Wallet (optional)</div>
        <div className="inline-line"></div>
      </RegisterConnectText>

      <RegisterConnectButtonsWrapper>
        <RegisterConnectButton
          disabled={loading}
          onClick={() => carvSingUp()}
          style={{ backgroundColor: '#000', color: '#fff' }}
        >
          <img src={carvIcon} alt="carv-icon" />
          <span>Continue with CARV ID</span>
        </RegisterConnectButton>
        {account && typeOfConnector ? (
          <>
            <RegisterConnectedInfo>
              <img
                src={typeOfConnector === 'Metamask' ? metaMaskIcon : geminiIcon}
                alt="metamask-icon"
              />
              <div>{`${account.substring(0, 6)}...${account.substring(
                38,
              )}`}</div>
            </RegisterConnectedInfo>
            {account && !checkWalletLoading && isConnected && (
              <TextError>
                The wallet is connecting to another player.
                <br />
                Please switch to other wallets
              </TextError>
            )}
            <RegisterConnectButton onClick={disconnect}>
              <span>Disconnect</span>
            </RegisterConnectButton>
          </>
        ) : (
          <>
            <RegisterConnectButton
              disabled={loading}
              onClick={async () => {
                await onConnectMetamaskWallet();
                setTypeOfConnector('Metamask');
              }}
            >
              <img src={metaMaskIcon} alt="metamask-icon" />
              <span>Connect Metamask</span>
            </RegisterConnectButton>
            <Tooltip title="Coming soon">
              <RegisterConnectButton disabled>
                <img src={geminiIcon} alt="gemini-icon" />
                <span>Gemini</span>
              </RegisterConnectButton>
            </Tooltip>
          </>
        )}
      </RegisterConnectButtonsWrapper>
    </Wrapper>
  );
};
